import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import cx from "classnames";

import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import { isValidNumber, AsYouType } from "libphonenumber-js";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import {
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  Link,
  Switch,
  FormControlLabel,
  Tooltip,
  CardContent,
  Card,
  CardHeader,
  ClickAwayListener
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import SweetAlert from "react-bootstrap-sweetalert";

import styles from "./styles";

moment.locale("pt-br");

const initialState = {
  date: null,
  name: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  people: 2,
  info: "",
  babySeat: false,
  disableClickAway: false,
  showPeopleInput: false,
  loading: {},
  errors: {},
  showOutTime: false,
  times: []
};

export class Home extends Component {
  state = {
    tables: [],
    business: null,
    alert: null,
    ...initialState
  };

  componentDidMount() {
    this.setState({
      loading: { ...this.state.loading, main: true, times: true }
    });

    try {
      var businessId = process.env.REACT_APP_BUSINESS_ID;
      var url = process.env.REACT_APP_API;

      fetch(`${url}/Business/${businessId}/Public`)
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            business: json.model,
            loading: { ...this.state.loading, main: false }
          });
        });
    } catch (error) {
      this.setState({
        loading: { ...this.state.loading, main: false }
      });
    }

    this.bindEvent(window, "message", function (e) {});
  }

  componentDidUpdate() {
    let { date, business } = this.state;

    if (!date && business) {
      this.setState({
        loading: { ...this.state.loading, main: true }
      });

      try {
        let defaultDate = moment();
        if (this.isWorkingNow()) defaultDate.add(1, "day");
        let dayOfWeek = defaultDate.weekday();

        const find = (c) => c.dayOfWeek === dayOfWeek;
        while (!date) {
          let day = business.settings.days.find(find);

          if (!day || !day.open) {
            defaultDate.add(1, "day");
            dayOfWeek = defaultDate.weekday();
            continue;
          }

          for (const hour of day.hours) {
            if (!hour.acceptBooking) {
              continue;
            }

            let start = moment(
              defaultDate.format("DD/MM/YYYY") + " " + hour.start,
              "DD/MM/YYYY HH:mm:ss"
            );

            let end = moment(
              defaultDate.format("DD/MM/YYYY") + " " + hour.end,
              "DD/MM/YYYY HH:mm:ss"
            );

            if (start.isSameOrAfter(end)) end.add(1, "day");

            if (start.isSameOrBefore(moment()) && end.isSameOrAfter(moment())) {
              start = moment();
              if (start.minute() % 15 !== 0)
                start.minute(Math.ceil((start.minute() + 1) / 15) * 15);
            }

            date = start;
          }

          if (!date) {
            defaultDate.add(1, "day");
            dayOfWeek = defaultDate.weekday();
          }
        }

        this.handleChange("date", date);
        this.checkTimesAvailable(date);
        this.setState({
          loading: { ...this.state.loading, main: false }
        });
      } catch (error) {
        this.setState({
          loading: { ...this.state.loading, main: false }
        });
      }
    }
  }

  bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, eventHandler);
    }
  }

  checkAvailable(date) {
    var businessId = process.env.REACT_APP_BUSINESS_ID;
    var url = process.env.REACT_APP_API;

    fetch(`${url}/Booking/Table/Available/${businessId}?selectedDate=${date}`)
      .then((res) => res.json())
      .then((json) => this.setState({ tables: json.model }));
  }

  async checkTimesAvailable(date) {
    var businessId = process.env.REACT_APP_BUSINESS_ID;
    var url = process.env.REACT_APP_API;

    this.setState({ loading: { ...this.state.loading, times: true } });

    let times = [];

    if (this.state.business) {
      let selectedDay = this.getSelectedDay(
        this.state.business.settings.days,
        date
      );

      if (selectedDay && selectedDay.open) {
        let hour = selectedDay.hours[0];
        let start = moment(hour.start, "HH:mm:ss");
        let end = moment(hour.end, "HH:mm:ss");

        while (start.isBefore(end)) {
          times.push({ time: start.format("HH:mm"), tables: [] });

          start = start.add(30, "minutes");
        }
      }
    }

    let dates = times.map((time) => {
      let str = date.format("DD/MM/YYYY") + " " + time.time;
      return moment(str, "DD/MM/YYYY HH:mm");
    });

    let res = await fetch(`${url}/Booking/Table/Available/${businessId}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        timezone: moment().format("Z")
      },
      body: JSON.stringify(dates)
    });

    if (res.ok) {
      let result = await res.json();
      if (result.isValid) {
        for (const key of Object.keys(result.model)) {
          let str = moment(key).format("HH:mm");
          let time = times.find((c) => c.time === str);

          if (time) time.tables = result.model[key];
        }
      } else {
        console.log(result.error);
      }
    } else {
      console.log(res.statusText);
    }

    this.setState({
      times: [...times],
      loading: { ...this.state.loading, times: false }
    });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submitBooking = () => {
    this.setState({ loading: { ...this.state.loading, booking: true } });

    try {
      const {
        name,
        lastName,
        phoneNumber,
        email,
        date,
        people,
        info,
        babySeat
      } = this.state;

      const errors = {};

      if (!name) errors.name = "Obrigatório";
      if (!lastName) errors.lastName = "Obrigatório";
      if (!phoneNumber) errors.phoneNumber = "Obrigatório";
      else if (!isValidNumber(phoneNumber, "BR"))
        errors.phoneNumber = "Inválido";
      if (!people) errors.people = "Obrigatório";
      if (!date) errors.date = "Obrigatório";
      if (email && !this.validateEmail(email)) errors.email = "Inválido";

      this.setState({ errors });

      if (_.keys(errors).length > 0) {
        this.setState({ loading: { ...this.state.loading, booking: false } });
        return;
      }

      var businessId = process.env.REACT_APP_BUSINESS_ID;
      var url = process.env.REACT_APP_API;

      fetch(`${url}/Booking/Business/${businessId}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          timezone: moment().format("Z")
        },
        body: JSON.stringify({
          user: {
            name,
            lastName,
            email,
            phoneNumber: "55" + phoneNumber.match(/\d/g).join("")
          },
          date,
          people,
          info,
          babySeat,
          status: 0
        })
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.isValid) {
            this.setState({
              ...initialState,
              alert: (
                <SweetAlert
                  success
                  title="Solicitação realizada"
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  onConfirm={() => this.setState({ alert: null })}
                >
                  <Typography>
                    Sua solicitação de reserva para o dia {moment(date).format("DD/MM/YYYY")}{" "}
                    às {moment(date).format("HH:mm")} foi realizada com sucesso. Vamos verificar disponibilidade e entraremos em contato.
                    Você vai receber mais informações pelo celular ou e-mail,
                    aguarde nossa confirmação. O Amaite Praia agradece pela
                    preferência.
                  </Typography>
                </SweetAlert>
              )
            });
          }
        });
    } catch (error) {
      this.setState({ loading: { ...this.state.loading, booking: false } });
    }
  };

  submitQueue = () => {
    this.setState({ loading: { ...this.state.loading, queue: true } });

    try {
      const { name, lastName, phoneNumber, email, people } = this.state;

      const errors = {};

      if (!name) errors.name = "Obrigatório";
      if (!lastName) errors.lastName = "Obrigatório";

      if (!phoneNumber) errors.phoneNumber = "Obrigatório";
      else if (!isValidNumber(phoneNumber, "BR"))
        errors.phoneNumber = "Inválido";

      if (!people) errors.people = "Obrigatório";
      if (email && !this.validateEmail(email)) errors.email = "Inválido";

      this.setState({ errors });

      if (_.keys(errors).length > 0) {
        this.setState({ loading: { ...this.state.loading, queue: false } });
        return;
      }

      var businessId = process.env.REACT_APP_BUSINESS_ID;
      var url = process.env.REACT_APP_API;

      fetch(`${url}/Queue/Business/${businessId}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          timezone: moment().format("Z")
        },
        body: JSON.stringify({
          user: {
            name,
            lastName,
            email,
            phoneNumber: "55" + phoneNumber.match(/\d/g).join("")
          },
          people
        })
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.isValid) {
            this.setState({
              ...initialState,
              alert: (
                <SweetAlert
                  success
                  title={<Typography variant="h5">Fila de espera</Typography>}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  onConfirm={() => this.setState({ alert: null })}
                >
                  <Typography>
                    Você entrou para a fila de espera, entraremos em contato
                    quando sua mesa estiver disponível. O Amaite Praia agradece
                    pela preferência.
                  </Typography>
                </SweetAlert>
              )
            });
          } else {
            if (json.errors) {
              if (json.errors.default) {
                if (
                  json.errors.default.includes("User already is in the queue.")
                ) {
                  this.setState({
                    alert: (
                      <SweetAlert
                        warning
                        title="Oops..."
                        confirmBtnCssClass={
                          this.props.classes.button +
                          " " +
                          this.props.classes.warning
                        }
                        onConfirm={() => this.setState({ alert: null })}
                      >
                        <Typography>
                          Parece que você já esta na fila de espera, você pode
                          verificar sua senha em uma dessas opções: <br />
                          <br />
                          <div>
                            No aplicativo{" "}
                            <Link
                              onClick={() =>
                                window.open("http://www.connecty.me/baixar")
                              }
                            >
                              Connecty.Me
                            </Link>
                            <br />
                            No seu e-mail
                            <br />
                            No seu WhatsApp
                            <br />
                            No seu aplicativo de SMS
                          </div>
                        </Typography>
                      </SweetAlert>
                    )
                  });
                }
              }
            }
            this.setState({ loading: { ...this.state.loading, queue: false } });
          }
        });
    } catch (error) {
      console.log(error);

      this.setState({ loading: { ...this.state.loading, queue: false } });
    }
  };

  handlePeopleClick = (people) => {
    if (people === "+") this.setState({ showPeopleInput: true, people: 2 });
    else this.setState({ people, showPeopleInput: false });
  };

  handleChange = (id, value) => this.setState({ [id]: value });

  getSelectedDay = (days, date) => {
    let dayOfWeek = date.weekday();
    if (!days || !date) return null;

    return days.find((c) => c.dayOfWeek === dayOfWeek);
  };

  isWorkingNow = () => {
    const { business } = this.state;

    if (business) {
      let selectedDay = this.getSelectedDay(business.settings.days, moment());

      if (selectedDay) {
        for (const hour of selectedDay.hours) {
          let start = moment(hour.start, "HH:mm:ss").subtract(90, "minute");
          let end = moment(hour.end, "HH:mm:ss");

          if (start.isSameOrAfter(end)) end = end.add(1, "day");

          if (start.isSameOrBefore(moment()) && end.isSameOrAfter(moment()))
            return true;
        }
      }
    }
    return false;
  };

  isWorkingTime = () => {
    return this._isWorkingTime(this.state.date);
  };

  _isWorkingTime = (date) => {
    const { business } = this.state;

    if (business && date) {
      let selectedDay = this.getSelectedDay(business.settings.days, date);

      if (selectedDay) {
        for (const hour of selectedDay.hours) {
          let start = moment(hour.start, "HH:mm:ss");
          let end = moment(hour.end, "HH:mm:ss");

          start = date.clone().hour(start.hour()).minute(start.minute());

          end = date.clone().hour(end.hour()).minute(end.minute());

          if (start.isSameOrAfter(end)) end = end.add(1, "day");

          if (start.isSameOrBefore(date) && end.isSameOrAfter(date)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  isToday = () => {
    const { date } = this.state;
    return this._isToday(date);
  };

  _isToday = (date) => {
    return (
      date && moment(date).format("YYYYMMDD") === moment().format("YYYYMMDD")
    );
  };

  handleClose = () => {
    if (this.state.disableClickAway) return;

    window.parent.postMessage("close", "*");
  };

  render() {
    const { classes } = this.props;

    const {
      name,
      lastName,
      email,
      phoneNumber,
      people,
      showPeopleInput,
      info,
      date,
      business,
      loading,
      babySeat,
      alert,
      showOutTime,
      times,
      errors
    } = this.state;

    let disponible = false;

    let time = times.find((c) => c.time === moment(date).format("HH:mm"));

    if (!time) {
      time = times[0];
    }

    if (date && time && time.tables) {
      let places = 0;

      for (const table of time.tables) {
        if (!places && table.people >= people) {
          places = table.people;
          break;
        } else if (table.canGroup) {
          places += table.people;

          if (places >= people) break;
        }
      }

      disponible = places >= people;
    }

    let canSubmit =
      name && phoneNumber && people && date && disponible && !loading.booking;

    let isWorkingTime = this.isWorkingTime();
    let selectedDay = null;

    if (business && date) {
      selectedDay = this.getSelectedDay(business.settings.days, date);
    }

    let isWorkingNow = this.isWorkingNow();

    let isToday = this.isToday();

    let canSubmitQueue =
      name && phoneNumber && people && date && isToday && !loading.queue;

    let queueEnabled =
      business && business.settings && business.settings.enableQueue;

    let closedForDay = false;

    if (business && business.settings && business.settings.queueClosingDate) {
      closedForDay = moment
        .parseZone(business.settings.queueClosingDate)
        .local()
        .startOf("day")
        .isSame(moment().startOf("day"));
    }

    return (
      <div className={classes.root}>
        {loading.main ? (
          <div className={classes.cover}>
            <Spinner style={{ height: 50, width: 50 }} />
          </div>
        ) : (
          <Grid container justify="center" alignContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4}>
              <ClickAwayListener onClickAway={this.handleClose}>
                <Card>
                  <CardHeader
                    action={
                      <div>
                        <a
                          href="https://www.connecty.me/?utm_source=AmaitePraia&utm_medium=Booking"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{}}
                            alt="connecty.me logo"
                            height={36}
                            src="img/logo-inversa.png"
                          />
                        </a>
                        <IconButton
                          aria-label="close"
                          className={classes.closeButton}
                          onClick={this.handleClose}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    }
                    classes={{ action: classes.cardAction }}
                  />
                  <CardContent style={{ marginTop: -52 }}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Typography variant="body2">
                          <strong>Informe seus dados:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          id="name"
                          label="Nome"
                          value={name}
                          fullWidth
                          className={classes.textField}
                          onChange={({ target: { value } }) =>
                            this.handleChange("name", value)
                          }
                          variant="outlined"
                          helperText={errors["name"]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="lastName"
                          required
                          label="Sobrenome"
                          value={lastName}
                          fullWidth
                          className={classes.textField}
                          onChange={({ target: { value } }) =>
                            this.handleChange("lastName", value)
                          }
                          variant="outlined"
                          helperText={errors["lastName"]}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Typography variant="body2">
                          <strong>
                            Informe seu celular e se preferir seu e-mail:
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          id="phoneNumber"
                          fullWidth
                          label="Celular"
                          value={phoneNumber}
                          className={classes.textField}
                          onChange={({ target: { value } }) =>
                            this.handleChange(
                              "phoneNumber",
                              new AsYouType("BR").input(value)
                            )
                          }
                          variant="outlined"
                          helperText={errors["phoneNumber"]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="email"
                          fullWidth
                          label="E-mail"
                          value={email}
                          placeholder=""
                          className={classes.textField}
                          onChange={({ target: { value } }) =>
                            this.handleChange("email", value)
                          }
                          variant="outlined"
                          helperText={errors["email"]}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Typography variant="body2">
                          <strong>Sua reserva é para quantas pessoas?</strong>
                        </Typography>
                      </Grid>
                      {!showPeopleInput ? (
                        <Grid container justify="space-around">
                          {[2, 3, 4, 5, 6, 7, 8, 9, 10, "+"].map((c, i) => (
                            <Grid item xs={1} key={i}>
                              <IconButton
                                classes={{
                                  label: classes.peopleButtonLabel,
                                  root: cx({
                                    [classes.peopleButtonActive]:
                                      c === people ||
                                      (c === "+" && showPeopleInput)
                                  })
                                }}
                                onClick={() => this.handlePeopleClick(c)}
                              >
                                {c}
                              </IconButton>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          style={{ paddingBottom: 0, display: "inline-block" }}
                        >
                          <TextField
                            id="people"
                            label="Quantidade de pessoas"
                            value={people}
                            type="number"
                            placeholder=""
                            className={classes.textField}
                            onChange={({ target: { value } }) =>
                              this.handleChange(
                                "people",
                                value === ""
                                  ? +value
                                  : isNaN(value)
                                  ? 1
                                  : value < 1
                                  ? 1
                                  : Math.trunc(value)
                              )
                            }
                            variant="outlined"
                            helperText={errors["people"]}
                          />
                          <IconButton
                            style={{
                              margin: 4
                            }}
                            onClick={() => this.handlePeopleClick(2)}
                          >
                            <i className="fas fa-undo-alt" />
                          </IconButton>
                        </Grid>
                      )}

                      <Grid container item xs={12}>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <Typography variant="body2">
                            <strong>
                              Qual a data e horário de sua reserva?
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              variant="outlined"
                              fullWidth
                              value={date}
                              onOpen={() =>
                                this.setState({ disableClickAway: true })
                              }
                              onClose={() =>
                                this.setState({ disableClickAway: false })
                              }
                              disablePast
                              format="DD/MM/YYYY"
                              maxDate={moment().add(15, "days")}
                              // shouldDisableDate={date => date.day() === 0}
                              onChange={(date) => {
                                if (
                                  this._isToday(date) &&
                                  this.isWorkingNow() &&
                                  !queueEnabled
                                ) {
                                  this.setState({ showOutTime: true });
                                } else {
                                  let time = times.find(
                                    (c) =>
                                      c.time === moment(date).format("HH:mm")
                                  );

                                  if (!time) {
                                    time = times[0];
                                  }
                                  let newDate = date;

                                  if (time) {
                                    newDate = moment(
                                      date.format("DD/MM/YYYY") +
                                        " " +
                                        time.time,
                                      "DD/MM/YYYY HH:mm"
                                    );
                                  }

                                  this.handleChange("date", newDate);
                                  this.checkTimesAvailable(newDate);
                                }
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            style={{
                              height: "100%",
                              minHeight: 55,
                              flexWrap: "nowrap",
                              paddingLeft: 4,
                              overflowX: "auto",
                              marginLeft: 4
                            }}
                          >
                            {isWorkingTime &&
                            isToday &&
                            isWorkingNow ? null : loading.times ? (
                              <Typography>
                                Carregando mesas e horários disponíveis...
                              </Typography>
                            ) : (
                              times.map((c, i) => {
                                let places = 0;
                                let hasTable = false;

                                for (const table of c.tables) {
                                  if (!places && table.people >= people) {
                                    places = table.people;
                                    break;
                                  } else if (table.canGroup) {
                                    places += table.people;

                                    if (places >= people) break;
                                  }
                                }

                                hasTable = places >= people;

                                if (hasTable)
                                  return (
                                    <div key={i}>
                                      <Button
                                        style={{ minHeight: 30 }}
                                        disabled={!hasTable}
                                        classes={{
                                          label: classes.peopleButtonLabel,
                                          root: cx({
                                            [classes.peopleButtonActive]:
                                              c.time ===
                                              moment(date).format("HH:mm")
                                          })
                                        }}
                                        onClick={() => {
                                          let newDate = moment(
                                            date.format("DD/MM/YYYY") +
                                              " " +
                                              c.time,
                                            "DD/MM/YYYY HH:mm"
                                          );

                                          this.handleChange("date", newDate);

                                          this.checkTimesAvailable(newDate);
                                        }}
                                      >
                                        {c.time}
                                      </Button>
                                    </div>
                                  );

                                return (
                                  <div key={i}>
                                    <Tooltip
                                      title={`Nenhuma mesa disponível nesse horário para ${people} ${
                                        people > 1 ? "pessoas" : "pessoa"
                                      }`}
                                      placement="top"
                                      classes={{
                                        tooltip: classes.lightTooltip
                                      }}
                                    >
                                      <div>
                                        <Button
                                          style={{ minHeight: 30 }}
                                          disabled={!hasTable}
                                          classes={{
                                            label: classes.peopleButtonLabel,
                                            root: cx({
                                              [classes.peopleButtonActive]:
                                                c.time ===
                                                moment(date).format("HH:mm")
                                            })
                                          }}
                                          onClick={() => {
                                            let newDate = moment(
                                              date.format("DD/MM/YYYY") +
                                                " " +
                                                c.time,
                                              "DD/MM/YYYY HH:mm"
                                            );

                                            this.handleChange("date", newDate);

                                            this.checkTimesAvailable(newDate);
                                          }}
                                        >
                                          {c.time}
                                        </Button>
                                      </div>
                                    </Tooltip>
                                  </div>
                                );
                              })
                            )}
                          </Grid>
                        </Grid>{" "}
                        {!loading.times && !disponible && (
                          <Grid item xs={12}>
                            <Typography>
                              {disponible
                                ? errors["date"]
                                : `Nenhuma mesa disponível nesse horário para ${people} ${
                                    people > 1 ? "pessoas" : "pessoa"
                                  }`}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <Typography variant="body2">
                            <strong>Você precisa de cadeira para bebê?</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={babySeat}
                                onChange={(e, value) =>
                                  this.handleChange("babySeat", value)
                                }
                                value="babySeat"
                                color="primary"
                              />
                            }
                            label="Solicitar cadeira para bebê"
                          />
                        </Grid>
                      </Grid>
                      {isWorkingTime && !disponible && isToday ? null : (
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <Typography variant="body2">
                            <strong>
                              Se tiver alguma informação especial para sua
                              reserva, escreva no campo abaixo:
                            </strong>
                          </Typography>
                        </Grid>
                      )}
                      {isWorkingTime && !disponible && isToday ? null : (
                        <Grid item xs={12}>
                          <TextField
                            id="info"
                            label="Informações"
                            value={info}
                            multiline
                            fullWidth
                            className={classes.textField}
                            variant="outlined"
                            onChange={({ target: { value } }) =>
                              this.handleChange("info", value)
                            }
                          />
                        </Grid>
                      )}

                      {!queueEnabled && showOutTime && (
                        <SweetAlert
                          info
                          title={<Typography variant="h5">Oops</Typography>}
                          confirmBtnCssClass={
                            this.props.classes.button +
                            " " +
                            this.props.classes.success
                          }
                          onConfirm={() => this.setState({ showOutTime: null })}
                        >
                          <Typography>
                            No momento nossas reservas já estão completas
                            <br />
                            Você pode vir a qualquer horário por ordem de
                            chegada
                            <br />
                            Lembrando que não reservamos todo o restaurante e no
                            caso de estar cheio temos uma área de espera aonde
                            você pode consumir alguns produtos nela
                            <br />
                            Não deixe de vir
                            <br />
                            Estamos te esperando 😃
                          </Typography>
                        </SweetAlert>
                      )}

                      {isWorkingTime && isToday && isWorkingNow ? (
                        queueEnabled && !closedForDay ? (
                          <Grid item xs={12}>
                            <Button
                              className={classes.bookingButton}
                              classes={{ disabled: classes.buttonDisabled }}
                              fullWidth
                              disabled={!canSubmitQueue}
                              onClick={this.submitQueue}
                            >
                              <img
                                style={{ marginLeft: 8, marginRight: 8 }}
                                height="32"
                                alt="connecty.me icon"
                                src="img/connectyme-icon.png"
                              />
                              Entrar na fila de espera
                              {loading.queue ? (
                                <Spinner
                                  style={{ height: 15, width: 15, margin: 5 }}
                                />
                              ) : null}
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <Typography color="error" align="center">
                              Já fechamos nossa fila de espera por hoje
                              <br /> Agradecemos a preferência e esperamos vê-lo
                              outro dia
                            </Typography>
                          </Grid>
                        )
                      ) : (
                        <Grid item xs={12}>
                          <Button
                            className={classes.bookingButton}
                            classes={{ disabled: classes.buttonDisabled }}
                            fullWidth
                            disabled={!canSubmit}
                            onClick={this.submitBooking}
                          >
                            Solicitar
                            <img
                              style={{ marginLeft: 8, marginRight: 8 }}
                              height="32"
                              alt="connecty.me icon"
                              src="img/connectyme-icon.png"
                            />
                            reserva
                            {loading.booking ? (
                              <Spinner
                                thickness={6}
                                style={{
                                  height: 15,
                                  width: 15,
                                  margin: 5,
                                  color: "white"
                                }}
                              />
                            ) : null}
                          </Button>
                        </Grid>
                      )}

                      {selectedDay && !isWorkingTime ? (
                        <Grid item xs={12}>
                          <Typography color="error">
                            O horário escolhido está fora do nosso horário de
                            atendimento, o horário de atendimento para o dia
                            selecionado é das{" "}
                            {moment(
                              selectedDay.hours[0].start,
                              "HH:mm:ss"
                            ).format("HH:mm")}{" "}
                            às{" "}
                            {moment(
                              selectedDay.hours[0].end,
                              "HH:mm:ss"
                            ).format("HH:mm")}
                          </Typography>
                        </Grid>
                      ) : null}

                      {!closedForDay &&
                      queueEnabled &&
                      isWorkingTime &&
                      isWorkingNow &&
                      isToday ? (
                        <Grid item xs={12}>
                          <Typography color="error">
                            Não estamos aceitando reservas nesse horário, para
                            continuar, você pode entrar na fila de espera e
                            aguardar enquanto libera uma mesa.
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Grid>
          </Grid>
        )}

        {alert}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Home);
