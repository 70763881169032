import buttonStyles from "../../assets/jss/buttonStyle";

const styles = theme => ({
  ...buttonStyles,
  root: {},
  logo: {
    margin: 35,
    height: 100
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  subTitleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  title: {
    color: "white"
  },
  subTitle: {
    color: "white"
  },
  cardTitle: {
    textAlign: "center"
  },
  cardAction: {
    marginRight: 0,
    zIndex: 1
  },
  peopleButtonLabel: {
    width: 18,
    height: 18,
    [theme.breakpoints.down("sm")]: {
      width: 8,
      height: 8
    }
  },
  peopleButtonActive: {
    backgroundColor: "#00ca6e !important",
    color: "white"
  },
  bookingButton: {
    backgroundColor: "#00ca6e",
    color: "white",
    height: 60,
    "&:hover": {
      backgroundColor: "#01b161"
    }
  },
  buttonDisabled: {
    color: "#e4e4e4 !important"
  },
  cover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500
  },
  timeInfo: {
    color: "white",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginRight: 32
    },
    [theme.breakpoints.down("md")]: {
      margin: 32
    }
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  closeButton: {
    position: "absolute",
    right: -11,
    top: 0,
    color: theme.palette.grey[500]
  }
});

export default styles;
