import React, { Component } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Home from "./screens/Home";

import LogRocket from "logrocket";

import setupLogRocketReact from "logrocket-react";

if (process.env.REACT_APP_LOG_ROCKET) {
  setupLogRocketReact(LogRocket);

  LogRocket.init(process.env.REACT_APP_LOG_ROCKET, {
    network: {
      requestSanitizer: (request) => {
        if (
          request.body &&
          request.body.indexOf &&
          request.body.indexOf("password") > -1
        ) {
          let body = JSON.parse(request.body);

          if (body.password) body.password = null;

          request.body = JSON.stringify(body);
        }

        // request.headers["Authorization"] = null;

        return request;
      },
    },
  });
}

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["AccordAlt", "Montserrat", "sans-serif"].join(","),
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#00ca6e",
    },
  },
});

class App extends Component {
  render() {
    return (
      <div id="app">
        <MuiThemeProvider theme={theme}>
          <Home />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
